<template>
    <el-dialog
        :append-to-body="true"
        :visible.sync="visible_in_line"
        :close-on-click-modal="true"
        width="560px"
    >
        <div slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{ $t('page.tiktok_account.edit_custom_info') }}
            </div>
        </div>
        <el-form ref="rule_form" :rules="rules" :model="custom_info">
            <div class="flex space-x-6">
                <input
                    id="uploadImage"
                    ref="files"
                    type="file"
                    style="display: none"
                    @change="handleSelectImage"
                />

                <div
                    class="w-[160px] h-[160px] border-[1px] border-dashed border-border cursor-pointer"
                    @click="handleOpenSelectImage"
                >
                    <img
                        v-if="avatar_url"
                        :src="avatar_url"
                        class="w-full h-full object-contain"
                    />
                    <div v-else class="util-flex-center h-full">
                        <i class="el-icon-plus text-lg"></i>
                    </div>
                </div>

                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.name') }}
                    </p>
                    <el-form-item prop="display_name">
                        <el-input
                            v-model="custom_info.display_name"
                            size="small"
                            :placeholder="$t('common.name')"
                        ></el-input>
                    </el-form-item>

                    <p class="component-label mt-6">
                        {{ $t('common.description') }}
                    </p>
                    <el-input
                        v-model="custom_info.description"
                        size="small"
                        :placeholder="$t('common.description')"
                        type="textarea"
                        :rows="3"
                    ></el-input>
                </div>
            </div>
        </el-form>

        <template slot="footer">
            <div class="flex items-center justify-end mt-4">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="submitForm"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { editCustomInfoTiktokAccount } from '@/services/atosa-tiktok-ads/tiktok-account'
export default {
    props: ['visible', 'edit_tiktok_account', 'tiktok_account_id'],

    data() {
        return {
            custom_info: {
                display_name: '',
                avatar_url: '',
                description: ''
            },
            avatar_file: null,
            avatar_url: null,
            rules: {
                display_name: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'blur'
                    }
                ]
            }
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        edit_tiktok_account() {
            this.image = null

            this.custom_info.display_name =
                this.edit_tiktok_account.custom_info.display_name ||
                this.edit_tiktok_account.user_name

            this.custom_info.description =
                this.edit_tiktok_account.custom_info.description || ''

            this.custom_info.avatar_url = this.edit_tiktok_account.custom_info
                .avatar_url
                ? `${process.env.VUE_APP_ATOSA_TIKTOK_SERVER_STATIC}/${this.edit_tiktok_account.custom_info.avatar_url}`
                : ''

            this.avatar_url = this.custom_info.avatar_url
        }
    },

    methods: {
        async handleEditCustomInfo() {
            try {
                const formData = new FormData()
                formData.append('display_name', this.custom_info.display_name)
                formData.append('description', this.custom_info.description)
                if (this.avatar_file) {
                    formData.append('avatar_file', this.avatar_file)
                }

                await editCustomInfoTiktokAccount(
                    this.edit_tiktok_account.id,
                    formData
                )

                this.$emit('refetch')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }
        },

        handleSelectImage(e) {
            if (e.target && e.target.files && e.target.files.length) {
                this.avatar_file = e.target.files[0]
                this.avatar_url = URL.createObjectURL(e.target.files[0])
            }
        },

        handleOpenSelectImage() {
            document.getElementById('uploadImage').click()
        },

        submitForm() {
            this.$refs['rule_form'].validate((valid) => {
                if (valid) {
                    this.handleEditCustomInfo()
                }
            })
        }
    }
}
</script>
