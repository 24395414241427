<template>
    <section class="component-box">
        <div class="flex items-center justify-between mb-4">
            <p class="font-semibold">
                {{ $t('common.tiktok_business') }}
            </p>
            <el-button
                icon="el-icon-connection"
                size="small"
                type="primary"
                @click="handleRedirectConnect()"
                >{{ $t('button.connect') }}
            </el-button>
        </div>

        <el-alert
            :title="$t('common.desc_tiktok_business')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert>

        <el-table v-loading="m__loading" :data="tiktok_businesses">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column align="center" width="120" label="Avatar">
                <template slot-scope="scope">
                    <el-avatar :size="60">
                        <img
                            v-if="scope.row.custom_info.avatar_url"
                            class="object-cover"
                            :src="`${VUE_APP_ATOSA_TIKTOK_SERVER_STATIC}/${scope.row.custom_info.avatar_url}`"
                            alt=""
                        />
                        <p v-else>{{ scope.row.user_name }}</p>
                    </el-avatar>
                </template>
            </el-table-column>

            <el-table-column
                min-width="200"
                :show-overflow-tooltip="true"
                prop="core_user_id"
                label="ID Tiktok"
            />

            <el-table-column
                min-width="200"
                :show-overflow-tooltip="true"
                prop="user_name"
                :label="$t('table.header.username')"
            />

            <el-table-column min-width="240" :label="$t('common.name')">
                <template slot-scope="scope">
                    <p>
                        {{
                            scope.row.custom_info.display_name ||
                            scope.row.user_name
                        }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                :show-overflow-tooltip="true"
                prop="email"
                label="Email"
                min-width="240"
            />

            <el-table-column
                :show-overflow-tooltip="true"
                prop="custom_info.description"
                :label="$t('common.description')"
                min-width="240"
            />

            <el-table-column
                fixed="right"
                :label="$t('common.option')"
                width="320px"
            >
                <template slot-scope="scope">
                    <el-button
                        size="small"
                        icon="el-icon-plus"
                        @click="
                            $router.push({
                                path: `${p__group_route.ads_manager}/${p__route.tiktok_business}/connect?tiktok_business_id=${scope.row.core_user_id}`
                            })
                        "
                    >
                        Tạo TKQC
                    </el-button>

                    <el-button
                        size="small"
                        icon="el-icon-edit"
                        @click="handleEdit(scope.row)"
                    >
                        {{ $t('common.edit') }}
                    </el-button>

                    <el-button
                        size="small"
                        type="danger"
                        icon="el-icon-delete"
                        @click="handleConfirmDelete(scope.row)"
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <EditCustomInfoDialog
            :edit_tiktok_account="selected_edit_custom_info"
            :visible.sync="show_edit_custom_info_dialog"
            @refetch="fetchListTiktokAccounts"
        ></EditCustomInfoDialog>
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { disconnectTiktokAccount } from '@/services/atosa-tiktok-ads/tiktok-account'
import { getTiktokAccounts } from '@/services/atosa-tiktok-ads/tiktok-account'
import EditCustomInfoDialog from './edit-custom-info-dialog'

export default {
    components: {
        EditCustomInfoDialog
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            show_edit_custom_info_dialog: false,
            selected_edit_custom_info: null,
            VUE_APP_ATOSA_TIKTOK_SERVER_STATIC:
                process.env.VUE_APP_ATOSA_TIKTOK_SERVER_STATIC
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        },

        tiktok_businesses() {
            return this.$store.getters.tiktokBusiness.s__tiktok_businesses.filter(
                (item) => !item.is_system_account
            )
        },

        tiktok_account_id() {
            if (this.m__selected_tiktok_business) {
                return this.m__selected_tiktok_business.id
            }
            return ''
        }
    },

    methods: {
        async handleRedirectConnect() {
            this.$router.push({
                path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect`
            })
        },

        async handleConfirmDelete(val) {
            try {
                await this.$confirm(
                    this.$t('page.tiktok_account.message_confirm_delete'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            await this.handleDeleteAccount(val)
        },

        async handleEdit(val) {
            this.show_edit_custom_info_dialog = true
            this.selected_edit_custom_info = val
        },

        async handleDeleteAccount(val) {
            this.m__loading = true

            try {
                const response = await disconnectTiktokAccount({
                    tiktok_account_id: val.core_user_id
                })

                if (response.code === 0) {
                    this.p__showNotify(
                        'success',
                        this.$t(
                            'page.tiktok_account.message_delete_tiktok_account_success'
                        )
                    )

                    await this.fetchListTiktokAccounts()
                }
            } catch (error) {
                console.error(error)
                this.p__showNotify(
                    'error',
                    this.$t(
                        'page.tiktok_account.message_delete_tiktok_account_fail'
                    )
                )
            }

            this.m__loading = false
        },

        async fetchListTiktokAccounts() {
            const response = await getTiktokAccounts()

            if (response.code === 0 && response.data.list.length > 0) {
                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    response.data.list
                )
            } else {
                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    []
                )
            }
        }
    }
}
</script>
